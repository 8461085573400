import { Box } from '@chakra-ui/react';

export default function SectionColumn({ children, ...rest }) {
  return (
    <Box
      pos='relative'
      py={{
        base: 'md',
        sm: 'md',
        md: 'lg',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
