import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Link from 'next/link';

type BreadcrumbsProps = {
  items: {
    name: string;
    href: string;
  }[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumb
      spacing='8px'
      fontSize='sm'
      separator={<ChevronRightIcon color='brand.gray' fontSize='md' />}
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;

        return (
          <BreadcrumbItem key={index} isCurrentPage={isLast}>
            {isLast ? (
              <BreadcrumbLink fontWeight='500'>{item.name}</BreadcrumbLink>
            ) : (
              <BreadcrumbLink as={Link} href={item.href}>
                {item.name}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
